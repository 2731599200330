<template>
  <b-overlay :show="showOverlay">
    <div
      v-if="showOverlay"
      style="min-height: 200px"
    />
    <validation-observer
      v-if="!showOverlay"
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(saveRelation)"
        @reset.prevent="resetForm"
      >
        <b-row class="match-height">
          <b-col
            v-if="localRelationData.id && localRelationData.id !== ''"
            cols="12"
            md="12"
            class="d-flex justify-content-between flex-column"
          >
            <b-card title="Risicoprofiel">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="CompassIcon"
                    size="20"
                    :style="`color:${resolveRiskProfileColor(localRelationData.riskProfile)};`"
                  />
                  {{ localRelationData.riskProfile === 'NONE' || localRelationData.riskProfile === 'LOW' ? 'Laag risicoprofiel' : '' }}{{ localRelationData.riskProfile === 'MEDIUM' ? 'Medium risicoprofiel' : '' }}{{ localRelationData.riskProfile === 'HIGH' ? 'Hoog risicoprofiel' : '' }}
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="d-flex justify-content-between flex-column"
          >
            <b-card title="Bedrijfsgegevens">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <label>Particulier / Zakelijk</label>
                  <validation-provider
                    vid="required_if_company_or_consumer_is"
                  >
                    <div class="demo-inline-spacing mb-2">
                      <b-form-radio
                        v-model="localRelationData.type"
                        class="mt-0"
                        name="companyOrConsumer"
                        :value="1"
                      >
                        Particulier
                      </b-form-radio>
                      <b-form-radio
                        v-model="localRelationData.type"
                        class="mt-0"
                        name="companyOrConsumer"
                        :value="2"
                      >
                        Zakelijk
                      </b-form-radio>
                    </div>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 2"
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyName"
                    rules="required"
                  >
                    <b-form-group
                      label="Bedrijfsnaam"
                      label-for="companyName"
                    >
                      <b-form-input
                        id="companyName"
                        v-model="localRelationData.companyName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 2"
                  cols="12"
                  md="4"
                >
                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="companyActivity"
                    rules=""
                  >
                    <b-form-group
                      label-for="companyActivity"
                      :state="getValidationState(validationContext)"
                    >
                      <label>Aard van het bedrijf
                        <feather-icon
                          id="relation-row-company-activity"
                          class="cursor-pointer"
                          icon="InfoIcon"
                          size="16"
                        />
                        <b-tooltip
                          target="relation-row-company-activity"
                          placement="top"
                        >
                          <p class="mb-0">
                            De hoofdactiviteit moet worden ingevuld.
                          </p>
                        </b-tooltip>
                      </label>
                      <v-select
                        v-model="localRelationData.companyActivity"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="natureOfBusinessList"
                        :reduce="val => val.code"
                        :clearable="true"
                        label="name"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 2"
                  cols="12"
                  md="4"
                >
                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="companyLegalForm"
                    rules="required"
                  >
                    <b-form-group
                      label="Rechtsvorm"
                      label-for="companyLegalForm"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="localRelationData.companyLegalForm"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="legalFormOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        input-id="active"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 2"
                  cols="12"
                  md="4"
                >
                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="companyEstablishmentCountry"
                    rules=""
                  >
                    <b-form-group
                      label="Land van oprichting"
                      label-for="companyEstablishmentCountry"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="localRelationData.companyEstablishmentCountry"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryList"
                        :reduce="val => val.code"
                        :clearable="true"
                        label="name"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 2"
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyEstablishmentDate"
                    rules=""
                  >
                    <b-form-group
                      label="Datum van oprichting"
                      label-for="companyEstablishmentDate"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        id="companyEstablishmentDate"
                        v-model="localRelationData.companyEstablishmentDate"
                        :config="companyEstablishmentDateConfig"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 2"
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyKKFNumber"
                    rules=""
                  >
                    <b-form-group
                      label="KKF nummer"
                      label-for="companyKKFNumber"
                    >
                      <b-form-input
                        id="companyKKFNumber"
                        v-model="localRelationData.companyKKFNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 2"
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyFoundationRegisterNumber"
                    rules=""
                  >
                    <b-form-group
                      label="Stichtingenregister nummer"
                      label-for="companyFoundationRegisterNumber"
                    >
                      <b-form-input
                        id="companyFoundationRegisterNumber"
                        v-model="localRelationData.companyFoundationRegisterNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 2"
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyBaseNumber"
                    rules=""
                  >
                    <b-form-group
                      label="Stamnummer"
                      label-for="companyBaseNumber"
                    >
                      <b-form-input
                        id="companyBaseNumber"
                        v-model="localRelationData.companyBaseNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 2"
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="companyTaxNumber"
                    rules=""
                  >
                    <b-form-group
                      label="OBnummer"
                      label-for="companyTaxNumber"
                    >
                      <b-form-input
                        id="companyTaxNumber"
                        v-model="localRelationData.companyTaxNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="d-flex justify-content-between flex-column"
          >
            <b-card :title="localRelationData.type === 1 ? 'Persoonsgegevens': 'Contactpersoon'">
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  class="mb-1"
                >
                  <validation-provider
                    #default="validationContext"
                    name="gender"
                    rules="required_if:required_if_company_or_consumer_is,1"
                  >
                    <label>Geslacht</label>
                    <div
                      class="demo-inline-spacing"
                      style="margin-top:5px"
                    >
                      <b-form-radio
                        v-model="localRelationData.gender"
                        name="gender"
                        value="m"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Man
                      </b-form-radio>
                      <b-form-radio
                        v-model="localRelationData.gender"
                        name="gender"
                        value="f"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Vrouw
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="firstName"
                    rules="required_if:required_if_company_or_consumer_is,1"
                  >
                    <b-form-group
                      label="Voornaam"
                      label-for="firstName"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="localRelationData.firstName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Tussenvoegsel"
                    label-for="middleName"
                  >
                    <b-form-input
                      id="middleName"
                      v-model="localRelationData.middleName"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="5"
                >
                  <validation-provider
                    #default="validationContext"
                    name="lastName"
                    rules="required_if:required_if_company_or_consumer_is,1"
                  >
                    <b-form-group
                      label="Achternaam"
                      label-for="lastName"
                    >
                      <b-form-input
                        id="lastname"
                        v-model="localRelationData.lastName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <b-form-group
                    label="Tussenvoegsel (Meisjesnaam)"
                    label-for="maidenMiddleName"
                  >
                    <b-form-input
                      id="maidenMiddleName"
                      v-model="localRelationData.maidenMiddleName"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="5"
                >
                  <validation-provider
                    #default="validationContext"
                    name="maidenName"
                    rules=""
                  >
                    <b-form-group
                      label="Meisjesnaam"
                      label-for="maidenName"
                    >
                      <b-form-input
                        id="maidenName"
                        v-model="localRelationData.maidenName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 1"
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="maritalStatus"
                    rules="required_if:required_if_company_or_consumer_is,1"
                  >
                    <b-form-group
                      label="Burgelijke staat"
                      label-for="martialStatus"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="localRelationData.maritalStatus"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="martialOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        input-id="active"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.type === 1"
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="phone"
                    rules="required_if:required_if_company_or_consumer_is,2|integer|min:6"
                  >
                    <b-form-group
                      label="Telefoonnummer"
                      label-for="phone"
                    >
                      <b-form-input
                        id="phone"
                        v-model="localRelationData.phone"
                        type="text"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="mobile"
                    rules="required_if:required_if_company_or_consumer_is,1|integer:min:7"
                  >
                    <b-form-group
                      label="Mobiel telefoonnummer"
                      label-for="phone"
                    >
                      <b-form-input
                        id="mobile"
                        v-model="localRelationData.mobile"
                        type="text"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="whatsappPhone"
                    rules="integer:min:7"
                  >
                    <b-form-group
                      label="Whats app nummer"
                      label-for="phone"
                    >
                      <b-form-input
                        id="whatsappPhone"
                        v-model="localRelationData.whatsappPhone"
                        type="text"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="email"
                    rules="required_if:required_if_company_or_consumer_is,2|email"
                  >
                    <b-form-group
                      label="E-mailadres"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="localRelationData.email"
                        type="email"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Field: birthdate -->
                <b-col
                  v-if="localRelationData.type === 1"
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="birthDate"
                    rules="required_if:required_if_company_or_consumer_is,1"
                  >
                    <b-form-group
                      label="Geboortedatum"
                      label-for="birthDate"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        id="birthDate"
                        v-model="localRelationData.birthDate"
                        :config="birthDateConfig"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            v-if="localRelationData.type === 1"
            cols="12"
            md="12"
            class="d-flex justify-content-between flex-column"
          >
            <b-card title="Identiteit">
              <b-row>
                <!-- Field: nationality -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="nationality"
                    rules="required"
                  >
                    <b-form-group
                      label="Nationaliteit"
                      label-for="nationality"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="localRelationData.nationality"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="nationalityList"
                        :reduce="val => val.code"
                        :clearable="true"
                        label="name"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="mb-1"
                >
                  <validation-provider
                    #default="validationContext"
                    name="isResident"
                    rules=""
                  >
                    <label>Ingezetene</label>
                    <div
                      class="demo-inline-spacing"
                      style="margin-top:5px"
                    >
                      <b-form-radio
                        v-model="localRelationData.isResident"
                        name="isResident"
                        :value="0"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Nee
                      </b-form-radio>
                      <b-form-radio
                        v-model="localRelationData.isResident"
                        name="isResident"
                        :value="1"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Ja
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="occupation"
                    rules="required"
                  >
                    <b-form-group
                      label="Beroep"
                      label-for="occupation"
                    >
                      <b-form-input
                        id="occupation"
                        v-model="localRelationData.occupation"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="occupationSector"
                    rules=""
                  >
                    <b-form-group
                      label="Sector"
                      label-for="occupationSector"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="localRelationData.occupationSector"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="sectorList"
                        :reduce="val => val.code"
                        :clearable="true"
                        label="name"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="mb-1"
                >
                  <validation-provider
                    #default="validationContext"
                    name="isPEP"
                    rules=""
                  >
                    <label>PEP informatie
                      <feather-icon
                        id="relation-row-pep"
                        class="cursor-pointer"
                        icon="InfoIcon"
                        size="16"
                      />
                      <b-tooltip
                        target="relation-row-pep"
                        placement="top"
                      >
                        <p class="mb-0">
                          Politieke Prominente Persoon: een persoon die in het buitenland (buitenlandse PEP) of in Suriname (binnenlandse PEP) een vooraanstaande publieke functie bekleedt of heeft bekleed, zoals staatshoofden en regeringsleiders, hooggeplaatste politici en hoge overheids-, justitiële of militaire ambtenaren, directeuren en leden van het hoger management van staatsbedrijven, belangrijke kaderleden van politieke partijen, alsmede directe familieleden en naaste geassocieerden van een dergelijke persoon.
                        </p>
                      </b-tooltip>
                    </label>
                    <div
                      class="demo-inline-spacing"
                      style="margin-top:5px"
                    >
                      <b-form-radio
                        v-model="localRelationData.isPEP"
                        name="isPEP"
                        :value="0"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Nee
                      </b-form-radio>
                      <b-form-radio
                        v-model="localRelationData.isPEP"
                        name="isPEP"
                        :value="1"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Ja
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="birthPlace"
                    rules="required"
                  >
                    <b-form-group
                      label="Geboorteplaats"
                      label-for="birthPlace"
                    >
                      <b-form-input
                        id="birthPlace"
                        v-model="localRelationData.birthPlace"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="birthCountry"
                    rules=""
                  >
                    <b-form-group
                      label="Geboorteland"
                      label-for="birthCountry"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="localRelationData.birthCountry"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryList"
                        :reduce="val => val.code"
                        :clearable="true"
                        label="name"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="idType"
                    rules="required"
                  >
                    <b-form-group
                      label="Identiteitstype"
                      label-for="idType"
                      :state="getValidationState(validationContext)"
                    >

                      <v-select
                        v-model="localRelationData.idType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="idTypeOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        input-id="active"
                        :state="getValidationState(validationContext)"
                        :class="getValidationState(validationContext) ? '' : 'is-invalid'"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="idNumber"
                    rules="required"
                  >
                    <b-form-group
                      label="ID nummer"
                      label-for="idNumber"
                    >
                      <b-form-input
                        id="idNumber"
                        v-model="localRelationData.idNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="idIssueDate"
                    rules="required"
                  >
                    <b-form-group
                      label="Afgiftedatum"
                      label-for="idIssueDate"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        id="isIssueDate"
                        v-model="localRelationData.isIssueDate"
                        :config="isIssueDateConfig"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="idIssuingCountry"
                    rules=""
                  >
                    <b-form-group
                      label="Land van afgifte"
                      label-for="idIssuingCountry"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="localRelationData.idIssuingCountry"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryList"
                        :reduce="val => val.code"
                        :clearable="true"
                        label="name"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="idExpiryDate"
                    rules="required"
                  >
                    <b-form-group
                      label="Geldig tot"
                      label-for="idExpiryDate"
                      :state="getValidationState(validationContext)"
                    >
                      <flat-pickr
                        id="idExpiryDate"
                        v-model="localRelationData.idExpiryDate"
                        :config="idExpiryDateConfig"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="d-flex justify-content-between flex-column"
          >
            <b-card :title="localRelationData.type === 2 ? 'Bedrijfsadres' : 'Adresgegevens'">
              <!-- Field: street -->
              <b-row>
                <b-col
                  cols="12"
                  md="8"
                >
                  <validation-provider
                    #default="validationContext"
                    name="street"
                    rules="required"
                  >
                    <b-form-group
                      label="Straatnaam"
                      label-for="street"
                    >
                      <b-form-input
                        id="street"
                        v-model="localRelationData.street"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="houseNumber"
                    rules="required"
                  >
                    <b-form-group
                      label="Huisnummer"
                      label-for="houseNumber"
                    >
                      <b-form-input
                        id="houseNumber"
                        v-model="localRelationData.houseNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <!-- Field: postalcode -->

                <!--                <b-col-->
                <!--                    cols="12"-->
                <!--                    md="6"-->
                <!--                >-->
                <!--                  <b-form-group-->
                <!--                      label="Postcode"-->
                <!--                      label-for="postalcode"-->
                <!--                  >-->
                <!--                    <b-form-input-->
                <!--                        id="postalcode"-->
                <!--                        v-model="localRelationData.postalcode"-->
                <!--                    />-->
                <!--                  </b-form-group>-->
                <!--                </b-col>-->
                <b-col
                  cols="12"
                  md="4"
                >
                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="country"
                    rules="required"
                  >
                    <b-form-group
                      label="Land"
                      label-for="country"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="localRelationData.country"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countryList"
                        :reduce="val => val.code"
                        :clearable="true"
                        label="name"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.country === 'SR'"
                  cols="12"
                  md="4"
                >
                  <!-- Country -->
                  <validation-provider
                    #default="validationContext"
                    name="city"
                    rules="required"
                  >
                    <b-form-group
                      label="Plaats"
                      label-for="city"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="localRelationData.city"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="surinameCityList"
                        :reduce="val => val.code"
                        :clearable="true"
                        label="name"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-else
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="city"
                    rules=""
                  >
                    <b-form-group
                      label="Plaats"
                      label-for="city"
                    >
                      <b-form-input
                        id="city"
                        v-model="localRelationData.city"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="localRelationData.country === 'SR'"
                  cols="12"
                  md="4"
                >
                  <!-- District-->
                  <validation-provider
                    #default="validationContext"
                    name="district"
                    rules="required"
                  >
                    <b-form-group
                      label="District"
                      label-for="district"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="localRelationData.district"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="districtOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-else
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="district"
                    rules=""
                  >
                    <b-form-group
                      label="District"
                      label-for="district"
                    >
                      <b-form-input
                        id="district"
                        v-model="localRelationData.district"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="d-flex justify-content-between flex-column"
          >
            <b-card
              title="Bankgegevens"
              :class="banksError ? 'invalid':''"
            >
              <b-row>
                <b-col cols="12">
                  <b-alert
                    variant="danger"
                    :show="banksError"
                  >
                    <div class="alert-body">
                      U is verplicht om minimaal 1 bank toe te voegen.
                    </div>
                  </b-alert>
                </b-col>
                <b-col cols="12">
                  <div>
                    <validation-observer
                      ref="refBanksFormObserver"
                    >
                      <b-form
                        ref="refBanksForm"
                      >
                        <b-row
                          v-for="(item, index) in localRelationData.banks"
                          :key="item.index"
                          ref="banksRow"
                        >
                          <b-col
                            cols="5"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`bankNumber${index}`"
                              rules="required"
                            >
                              <b-form-group
                                label="Rekeningnummer"
                                :label-for="`bankNumber${index}`"
                              >
                                <b-form-input
                                  :id="`bankNumber${index}`"
                                  v-model="item.bankNumber"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            md="5"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`bankName${index}`"
                              rules="required"
                            >
                              <b-form-group
                                label="Land van vestiging bank"
                                :label-for="`bankCountry${index}`"
                                :state="getValidationState(validationContext)"
                              >
                                <v-select
                                  v-model="item.bankCountry"
                                  :name="`bankCountry${index}`"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="countryList"
                                  :reduce="val => val.code"
                                  :clearable="true"
                                  label="name"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            class="text-right align-text-center text-center"
                            cols="2"
                          >
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0"
                              @click="removeBanksItem(index)"
                            >
                              <feather-icon
                                icon="TrashIcon"
                                class="mr-25"
                              />
                            </b-button>
                          </b-col>
                          <b-col
                            v-if="item.bankCountry === 'SR'"
                            cols="12"
                            md="5"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`bankName${index}`"
                              rules="required"
                            >
                              <b-form-group
                                label="Bank"
                                :label-for="`bankName${index}`"
                                :state="getValidationState(validationContext)"
                              >
                                <v-select
                                  v-model="item.bankName"
                                  :name="`bankName${index}`"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="bankList"
                                  :reduce="val => val.code"
                                  :clearable="true"
                                  label="name"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            v-if="item.bankCountry !== 'SR'"
                            cols="12"
                            md="5"
                          >
                            <validation-provider
                              #default="validationContext"
                              name="bankName"
                              rules="required"
                            >
                              <b-form-group
                                label="Bank"
                                :label-for="`bankName${index}`"
                              >
                                <b-form-input
                                  v-model="item.bankName"
                                  :name="`bankName${index}`"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            md="5"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`bankCurrency${index}`"
                              rules="required"
                            >
                              <b-form-group
                                label="Rekening valuta"
                                :label-for="`bankCurrency${index}`"
                                :state="getValidationState(validationContext)"
                              >
                                <v-select
                                  v-model="item.bankCurrency"
                                  :name="`bankCurrency${index}`"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="currencyList"
                                  :reduce="val => val.code"
                                  :clearable="true"
                                  label="name"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <hr>
                          </b-col>
                        </b-row>
                      </b-form>
                    </validation-observer>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addBankItem"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>Toevoegen</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            v-if="localRelationData.type === 2"
            cols="12"
            md="12"
            class="d-flex justify-content-between flex-column"
          >
            <b-card
              title="UBO's"
              :class="uboError ? 'invalid':''"
            >
              <b-row>
                <b-col cols="12">
                  <b-alert
                    variant="danger"
                    :show="uboError"
                  >
                    <div class="alert-body">
                      U is verplicht om minimaal 1 ubo toe te voegen.
                    </div>
                  </b-alert>
                </b-col>
                <b-col cols="12">
                  <div>
                    <validation-observer
                      ref="refUboFormObserver"
                    >
                      <b-form
                        ref="refUboForm"
                      >
                        <b-row
                          v-for="(item, index) in localRelationData.ubos"
                          :key="item.index"
                          ref="row"
                        >
                          <b-col
                            cols="5"
                          >
                            <label :label-for="`type${index}`">Type</label>
                            <validation-provider
                              #default="validationContext"
                              :name="`type${index}`"
                              rules="required"
                            >
                              <div class="demo-inline-spacing mb-2">
                                <b-form-radio
                                  v-model="item.type"
                                  class="mt-0"
                                  :name="`type${index}`"
                                  value="DIRECTOR"
                                  :state="getValidationState(validationContext)"
                                >
                                  Directeur
                                </b-form-radio>
                                <b-form-radio
                                  v-model="item.type"
                                  class="mt-0"
                                  :name="`type${index}`"
                                  value="UBO"
                                  :state="getValidationState(validationContext)"
                                >
                                  Ulitmated beneficial owner
                                </b-form-radio>
                              </div>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            md="5"
                          >
                            <validation-provider
                              v-if="item.type === 'DIRECTOR'"
                              #default="validationContext"
                              :name="`percentage${index}`"
                              rules="required|between:1,100|integer"
                            >
                              <b-form-group
                                label="Zeggenschapsbelang"
                                :label-for="`percentage${index}`"
                              >
                                <b-form-input
                                  :id="`percentage${index}`"
                                  v-model="item.percentage"
                                  type="number"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            class="text-right align-text-center text-center"
                            cols="2"
                          >
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              class="mt-0"
                              @click="removeUboItem(index)"
                            >
                              <feather-icon
                                icon="TrashIcon"
                                class="mr-25"
                              />
                            </b-button>
                          </b-col>
                          <b-col
                            v-if="item.type === 2"
                            md="5"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`companyName${index}`"
                              rules="required"
                            >
                              <b-form-group
                                label="Bedrijfsnaam"
                                :label-for="`companyName${index}`"
                              >
                                <b-form-input
                                  :id="`companyName${index}`"
                                  v-model="item.companyName"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            md="4"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`itemFirstName${index}`"
                              rules="required"
                            >
                              <b-form-group
                                label="Voornaam"
                                :label-for="`itemFirstName${index}`"
                              >
                                <b-form-input
                                  :id="`itemFirstName${index}`"
                                  v-model="item.firstName"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            md="2"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`itemMiddleName${index}`"
                              rules=""
                            >
                              <b-form-group
                                label="Tussenvoegsel"
                                :label-for="`itemMiddleName${index}`"
                              >
                                <b-form-input
                                  :id="`itemMiddleName${index}`"
                                  v-model="item.middleName"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            md="4"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`itemLastName${index}`"
                              rules="required"
                            >
                              <b-form-group
                                label="Achternaam"
                                :label-for="`itemLastName${index}`"
                              >
                                <b-form-input
                                  :id="`itemLastName${index}`"
                                  v-model="item.lastName"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            md="5"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`itemNationality${index}`"
                              rules="required"
                            >
                              <b-form-group
                                label="Nationaliteit"
                                :label-for="`itemNationality${index}`"
                                :state="getValidationState(validationContext)"
                              >
                                <v-select
                                  :id="`itemNationality${index}`"
                                  v-model="item.nationality"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="nationalityList"
                                  :reduce="val => val.code"
                                  :clearable="true"
                                  label="name"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            md="5"
                            class="mb-1"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`itemIsResident${index}`"
                              rules="required"
                            >
                              <label>Ingezetene</label>
                              <div
                                class="demo-inline-spacing"
                                style="margin-top:5px"
                              >
                                <b-form-radio
                                  v-model="item.isResident"
                                  :name="`itemIsResident${index}`"
                                  :value="0"
                                  class="mt-0"
                                  :state="getValidationState(validationContext)"
                                >
                                  Nee
                                </b-form-radio>
                                <b-form-radio
                                  v-model="item.isResident"
                                  :name="`itemIsResident${index}`"
                                  :value="1"
                                  class="mt-0"
                                  :state="getValidationState(validationContext)"
                                >
                                  Ja
                                </b-form-radio>
                              </div>
                              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            md="5"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`itemIdType${index}`"
                              rules="required"
                            >
                              <b-form-group
                                label="Identiteitstype"
                                :label-for="`itemIdType${index}`"
                                :state="getValidationState(validationContext)"
                              >

                                <v-select
                                  :id="`itemIdType${index}`"
                                  v-model="item.idType"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="idTypeOptions"
                                  :reduce="val => val.value"
                                  :clearable="true"
                                  :state="getValidationState(validationContext)"
                                  :class="getValidationState(validationContext) ? '' : 'is-invalid'"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            md="5"
                          >
                            <validation-provider
                              #default="validationContext"
                              :name="`itemIdNumber${index}`"
                              rules="required"
                            >
                              <b-form-group
                                label="ID nummer"
                                :label-for="`itemIdNumber${index}`"
                              >
                                <b-form-input
                                  :id="`itemIdNumber${index}`"
                                  v-model="item.idNumber"
                                  :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col cols="12">
                            <hr>
                          </b-col>
                        </b-row>
                      </b-form>
                    </validation-observer>
                  </div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addUboItem"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>Toevoegen</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            v-if="localRelationData.id && type === 'UPDATE'"
            cols="12"
            class="d-flex justify-content-between flex-column m-0 p-0"
          >
            <app-relation-documents
              :relation-id="localRelationData.id"
              :files="localRelationData.files"
              :relation-type="localRelationData.type"
              @refetch-data="refetchData"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="6 text-left"
                >
                  <b-button
                    v-if="showBackButton"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    @click="$router.go(-1)"
                  >
                    <span>terug</span>
                  </b-button>
                </b-col>
                <b-col
                  cols="6 text-right"
                >
                  <b-button
                    v-if="$can('create', 'relation')"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="submit"
                  >
                    <b-spinner
                      v-if="handleButtonSpinner"
                      small
                      class="mr-1"
                    />
                    <span>{{ handleButtonText }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import
{
  BCard,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BFormRadio,
  BTooltip,
  BOverlay,
  BSpinner, BAlert,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  alphaNum,
  email,
  required,
  date,
  requiredIf,
  min, between, integer,
} from '@core/utils/validations/validations'
import useRelationsList from '@/views/pages/relations/useRelationsList'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import AppRelationDocuments from '@core/components/app-relation-documents/AppRelationDocuments.vue'
import {
  bankList,
  countryList,
  currencyList, nationalityList, natureOfBusinessList,
  sectorList, surinameCityList,
} from '@core/utils/list'
import { heightTransition } from '@core/mixins/ui/transition'
import appRelationStoreModule from '@core/components/app-relation/appRelationStoreModule'
import {
  birthDateConfig, companyEstablishmentDateConfig, idExpiryDateConfig, isIssueDateConfig,
} from '@core/utils/dateConfig'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormRadio,
    BTooltip,
    BOverlay,
    BSpinner,
    BAlert,
    vSelect,
    flatPickr,
    AppRelationDocuments,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    type: {
      type: String,
      required: true,
    },
    relationData: {
      type: Object,
      required: true,
    },
    showOverlay: {
      type: Boolean,
      required: true,
    },
    handleButtonSpinner: {
      type: Boolean,
      required: true,
    },
    handleButtonText: {
      type: String,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sectorList,
      countryList,
      currencyList,
      bankList,
      surinameCityList,
      natureOfBusinessList,
      nationalityList,
      required,
      alphaNum,
      between,
      email,
      date,
      requiredIf,
      integer,
      min,
      companyEstablishmentDateConfig,
      birthDateConfig,
      isIssueDateConfig,
      idExpiryDateConfig,
      localRelationData: {
        ubos: [],
        banks: [],
        bankCountry: null,
        bankCurrency: null,
        bankName: null,
        bankNumber: null,
        birthCountry: null,
        birthDate: null,
        birthPlace: null,
        city: null,
        companyActivity: null,
        companyBaseNumber: null,
        companyEstablishmentCountry: null,
        companyEstablishmentDate: null,
        companyFoundationRegisterNumber: null,
        companyKKFNumber: null,
        companyLegalForm: null,
        companyName: null,
        companyTaxNumber: null,
        country: null,
        district: null,
        documentStatus: null,
        email: null,
        files: [],
        firstName: null,
        gender: null,
        houseNumber: null,
        id: null,
        idExpiryDate: null,
        idIssuingCountry: null,
        idNumber: null,
        idType: null,
        isIssueDate: null,
        isPEP: null,
        isResident: null,
        lastName: null,
        maidenMiddleName: null,
        maidenName: null,
        maritalStatus: null,
        middleName: null,
        mobile: null,
        nationality: null,
        occupation: null,
        occupationSector: null,
        phone: null,
        riskProfile: null,
        street: null,
        type: 1,
        whatsappPhone: null,
      },
      uboError: false,
      banksError: false,
    }
  },
  watch: {
    relationData: {
      handler(data) {
        this.localRelationData = data
      },
      deep: true,
    },
  },
  created() {
    this.initUboTrHeight()
    this.initBanksTrHeight()
    window.addEventListener('resize', this.initUboTrHeight)
    window.addEventListener('resize', this.initBanksTrHeight)
  },
  methods: {
    async saveRelation() {
      this.$emit('handle-relation', this.localRelationData)
      // const formsToValidate = []
      //
      // if (this.localRelationData.ubos.length < 1 && this.localRelationData.type === 2) {
      //   this.uboError = true
      // }
      //
      // if (this.localRelationData.banks.length < 1) {
      //   this.banksError = true
      // }
      //
      // if (this.localRelationData.ubos.length > 0 && this.localRelationData.type === 2) {
      //   this.uboError = false
      //   formsToValidate.push(this.$refs.refUboFormObserver.validate())
      // }
      //
      // if (this.localRelationData.banks.length > 0) {
      //   this.banksError = false
      //   formsToValidate.push(this.$refs.refBanksFormObserver.validate())
      // }
      //
      // if (!this.uboError && !this.banksError) {
      //   await Promise.all(formsToValidate)
      //     .then(valid => {
      //       if (!valid.includes(false)) {
      //         this.$emit('handle-relation', this.localRelationData)
      //       } else {
      //         this.pageHasError = true
      //         // setTimeout(this.clearPageError, 5000)
      //       }
      //     })
      // }
    },
    addUboItem() {
      this.localRelationData.ubos.push({
        id: null,
        firstName: null,
        middleName: null,
        lastName: null,
        nationality: null,
        isResident: null,
        idType: null,
        idNumber: null,
        type: null,
        percentage: null,
        relationID: this.localRelationData.id,
      })

      this.$nextTick(() => {
        this.uboError = false
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    addBankItem() {
      this.localRelationData.banks.push({
        id: null,
        bankCurrency: null,
        bankNumber: null,
        bankCountry: null,
        bankName: null,
        relationID: this.localRelationData.id,
      })

      this.$nextTick(() => {
        this.banksError = false
        this.trAddHeight(this.$refs.banksRow[0].offsetHeight)
      })
    },
    removeUboItem(index) {
      this.localRelationData.ubos.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    removeBanksItem(index) {
      this.localRelationData.banks.splice(index, 1)
      this.trTrimHeight(this.$refs.banksRow[0].offsetHeight)
    },
    initUboTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (this.$refs.uboForm) {
          this.trSetHeight(this.$refs.refUboForm.scrollHeight)
        }
      })
    },
    initBanksTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (this.$refs.uboForm) {
          this.trSetHeight(this.$refs.refBanksForm.scrollHeight)
        }
      })
    },
    refetchData() {
      this.$emit('refetch-data')
    },
  },
  setup() {
    const RELATION_APP_STORE_MODULE_NAME = 'apps-relations'

    // Register module
    if (!store.hasModule(RELATION_APP_STORE_MODULE_NAME)) store.registerModule(RELATION_APP_STORE_MODULE_NAME, appRelationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RELATION_APP_STORE_MODULE_NAME)) store.unregisterModule(RELATION_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {
      resolveDistrictForCountry,
      countryOptions,
      districtOptions,
      legalFormOptions,
      idTypeOptions,
      martialOptions,
      resolveRiskProfileColor,
    } = useRelationsList()

    return {
      refFormObserver,
      resolveDistrictForCountry,
      getValidationState,
      resetForm,
      countryOptions,
      districtOptions,
      legalFormOptions,
      idTypeOptions,
      martialOptions,
      resolveRiskProfileColor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-organisation-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss" scoped>
.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}

.invalid{
  .card-title{
    color: #ea5455
  }
}
</style>
