<template>
  <app-relation
    type="UPDATE"
    :relation-data="relationData"
    :show-overlay="showRelationOverlay"
    :handle-button-spinner="relationHandleButtonSpinner"
    :show-back-button="true"
    handle-button-text="Opslaan"
    @handle-relation="handleRelation"
    @refetch-data="getRelation"
  />
</template>

<script>
import AppRelation from '@core/components/app-relation/AppRelation.vue'
import { onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import relationsStoreModule from '@/views/pages/relations/relationsStoreModule'

export default {
  components: {
    AppRelation,
  },
  data() {
    return {
      birthDateConfig: {
        maxDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      isIssueDateConfig: {
        maxDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      idExpiryDateConfig: {
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      relationData: {
        bankCountry: null,
        bankCurrency: null,
        bankName: null,
        bankNumber: null,
        birthCountry: null,
        birthDate: null,
        birthPlace: null,
        city: null,
        companyActivity: null,
        companyBaseNumber: null,
        companyEstablishmentCountry: null,
        companyEstablishmentDate: null,
        companyFoundationRegisterNumber: null,
        companyKKFNumber: null,
        companyLegalForm: null,
        companyName: null,
        companyTaxNumber: null,
        country: null,
        district: null,
        documentStatus: null,
        email: null,
        files: [],
        firstName: null,
        gender: null,
        houseNumber: null,
        id: null,
        idExpiryDate: null,
        idIssuingCountry: null,
        idNumber: null,
        idType: null,
        isIssueDate: null,
        isPEP: null,
        isResident: null,
        lastName: null,
        maidenMiddleName: null,
        maidenName: null,
        maritalStatus: null,
        middleName: null,
        mobile: null,
        nationality: null,
        occupation: null,
        occupationSector: null,
        phone: null,
        riskProfile: null,
        street: null,
        type: 1,
        whatsappPhone: null,
      },
      showRelationOverlay: true,
      relationHandleButtonSpinner: false,
    }
  },
  beforeMount() {
    this.getRelation()
  },
  methods: {
    handleRelation(data) {
      this.relationHandleButtonSpinner = true
      store.dispatch('apps-relations-edit/updateRelation', data)
        .then(() => {
          this.relationHandleButtonSpinner = false
          router.push({ name: 'apps-relations-list' })
        })
        .catch(() => {
          this.relationHandleButtonSpinner = false
          this.$swal({
            icon: 'error',
            title: 'Relatie updaten!',
            text: 'Er is een fout opgetreden bij het updaten van de relatie. Probeer het later nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    getRelation() {
      this.showRelationOverlay = true
      store.dispatch('apps-relations-edit/fetchRelation', { id: router.currentRoute.params.id })
        .then(response => {
          this.relationData = response.data
          this.showRelationOverlay = false
        })
        .catch(() => {
          this.showRelationOverlay = false
          this.$swal({
            icon: 'error',
            title: 'Relatie!',
            text: 'Er is een fout opgetreden bij het ophalen van de relatie. Probeer het later nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
  setup() {
    const RELATION_APP_STORE_MODULE_NAME = 'apps-relations-edit'

    // Register module
    if (!store.hasModule(RELATION_APP_STORE_MODULE_NAME)) store.registerModule(RELATION_APP_STORE_MODULE_NAME, relationsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RELATION_APP_STORE_MODULE_NAME)) store.unregisterModule(RELATION_APP_STORE_MODULE_NAME)
    })
  },
}
</script>
