// eslint-disable-next-line import/prefer-default-export

export const companyEstablishmentDateConfig = {
  maxDate: 'today',
  altFormat: 'd-m-Y',
  dateFormat: 'Y-m-d',
  altInput: true,
  allowInput: true,
}

export const birthDateConfig = {
  maxDate: 'today',
  altFormat: 'd-m-Y',
  dateFormat: 'Y-m-d',
  altInput: true,
  allowInput: true,
}

export const isIssueDateConfig = {
  maxDate: 'today',
  altFormat: 'd-m-Y',
  dateFormat: 'Y-m-d',
  altInput: true,
  allowInput: true,
}
export const idExpiryDateConfig = {
  altFormat: 'd-m-Y',
  dateFormat: 'Y-m-d',
  altInput: true,
  allowInput: true,
}
