// eslint-disable-next-line import/prefer-default-export
export const countryList = [
  {
    name: 'Afghanistan',
    code: 'AF',
  },
  {
    name: '\u00c5land Islands',
    code: 'AX',
  },
  {
    name: 'Albania',
    code: 'AL',
  },
  {
    name: 'Algeria',
    code: 'DZ',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'Andorra',
    code: 'AD',
  },
  {
    name: 'Angola',
    code: 'AO',
  },
  {
    name: 'Anguilla',
    code: 'AI',
  },
  {
    name: 'Antarctica',
    code: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
  },
  {
    name: 'Argentina',
    code: 'AR',
  },
  {
    name: 'Armenia',
    code: 'AM',
  },
  {
    name: 'Aruba',
    code: 'AW',
  },
  {
    name: 'Australia',
    code: 'AU',
  },
  {
    name: 'Austria',
    code: 'AT',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
  },
  {
    name: 'Bahamas',
    code: 'BS',
  },
  {
    name: 'Bahrain',
    code: 'BH',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
  },
  {
    name: 'Barbados',
    code: 'BB',
  },
  {
    name: 'Belarus',
    code: 'BY',
  },
  {
    name: 'Belgium',
    code: 'BE',
  },
  {
    name: 'Belize',
    code: 'BZ',
  },
  {
    name: 'Benin',
    code: 'BJ',
  },
  {
    name: 'Bermuda',
    code: 'BM',
  },
  {
    name: 'Bhutan',
    code: 'BT',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    code: 'BO',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    code: 'BQ',
  },
  {
    name: 'Bosnia and Herzegovina (Balkan)',
    code: 'BA',
  },
  {
    name: 'Botswana',
    code: 'BW',
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
  },
  {
    name: 'Brazil',
    code: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    code: 'BN',
  },
  {
    name: 'Bulgaria (Balkan)',
    code: 'BG',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
  },
  {
    name: 'Burundi',
    code: 'BI',
  },
  {
    name: 'Cabo Verde',
    code: 'CV',
  },
  {
    name: 'Cambodia',
    code: 'KH',
  },
  {
    name: 'Cameroon',
    code: 'CM',
  },
  {
    name: 'Canada',
    code: 'CA',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
  },
  {
    name: 'Chad',
    code: 'TD',
  },
  {
    name: 'Chile',
    code: 'CL',
  },
  {
    name: 'China',
    code: 'CN',
  },
  {
    name: 'Christmas Island',
    code: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
  },
  {
    name: 'Colombia',
    code: 'CO',
  },
  {
    name: 'Comoros',
    code: 'KM',
  },
  {
    name: 'Congo',
    code: 'CG',
  },
  {
    name: 'Congo, Democratic Republic of the',
    code: 'CD',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
  },
  {
    name: "C\u00f4te d'Ivoire",
    code: 'CI',
  },
  {
    name: 'Croatia (Balkan)',
    code: 'HR',
  },
  {
    name: 'Cuba',
    code: 'CU',
  },
  {
    name: 'Cura\u00e7ao',
    code: 'CW',
  },
  {
    name: 'Cyprus',
    code: 'CY',
  },
  {
    name: 'Czechia',
    code: 'CZ',
  },
  {
    name: 'Denmark',
    code: 'DK',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
  },
  {
    name: 'Dominica',
    code: 'DM',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
  },
  {
    name: 'Ecuador',
    code: 'EC',
  },
  {
    name: 'Egypt',
    code: 'EG',
  },
  {
    name: 'El Salvador',
    code: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
  },
  {
    name: 'Eritrea',
    code: 'ER',
  },
  {
    name: 'Estonia',
    code: 'EE',
  },
  {
    name: 'Eswatini',
    code: 'SZ',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
  },
  {
    name: 'Fiji',
    code: 'FJ',
  },
  {
    name: 'Finland',
    code: 'FI',
  },
  {
    name: 'France',
    code: 'FR',
  },
  {
    name: 'French Guiana',
    code: 'GF',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
  },
  {
    name: 'French Southern Territories',
    code: 'TF',
  },
  {
    name: 'Gabon',
    code: 'GA',
  },
  {
    name: 'Gambia',
    code: 'GM',
  },
  {
    name: 'Georgia',
    code: 'GE',
  },
  {
    name: 'Germany',
    code: 'DE',
  },
  {
    name: 'Ghana',
    code: 'GH',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
  },
  {
    name: 'Greece',
    code: 'GR',
  },
  {
    name: 'Greenland',
    code: 'GL',
  },
  {
    name: 'Grenada',
    code: 'GD',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Guatemala',
    code: 'GT',
  },
  {
    name: 'Guernsey',
    code: 'GG',
  },
  {
    name: 'Guinea',
    code: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
  },
  {
    name: 'Guyana',
    code: 'GY',
  },
  {
    name: 'Haiti',
    code: 'HT',
  },
  {
    name: 'Heard Island and McDonald Islands',
    code: 'HM',
  },
  {
    name: 'Holy See',
    code: 'VA',
  },
  {
    name: 'Honduras',
    code: 'HN',
  },
  {
    name: 'Hong Kong',
    code: 'HK',
  },
  {
    name: 'Hungary',
    code: 'HU',
  },
  {
    name: 'Iceland',
    code: 'IS',
  },
  {
    name: 'India',
    code: 'IN',
  },
  {
    name: 'Indonesia',
    code: 'ID',
  },
  {
    name: 'Iran (Islamic Republic of)',
    code: 'IR',
  },
  {
    name: 'Iraq',
    code: 'IQ',
  },
  {
    name: 'Ireland',
    code: 'IE',
  },
  {
    name: 'Isle of Man',
    code: 'IM',
  },
  {
    name: 'Israel',
    code: 'IL',
  },
  {
    name: 'Italy',
    code: 'IT',
  },
  {
    name: 'Jamaica',
    code: 'JM',
  },
  {
    name: 'Japan',
    code: 'JP',
  },
  {
    name: 'Jersey',
    code: 'JE',
  },
  {
    name: 'Jordan',
    code: 'JO',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
  },
  {
    name: 'Kenya',
    code: 'KE',
  },
  {
    name: 'Kiribati',
    code: 'KI',
  },
  {
    name: "Korea (Democratic People's Republic of)",
    code: 'KP',
  },
  {
    name: 'Korea, Republic of',
    code: 'KR',
  },
  {
    name: 'Kuwait',
    code: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
  },
  {
    name: "Lao People's Democratic Republic",
    code: 'LA',
  },
  {
    name: 'Latvia',
    code: 'LV',
  },
  {
    name: 'Lebanon',
    code: 'LB',
  },
  {
    name: 'Lesotho',
    code: 'LS',
  },
  {
    name: 'Liberia',
    code: 'LR',
  },
  {
    name: 'Libya',
    code: 'LY',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
  },
  {
    name: 'Lithuania',
    code: 'LT',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
  },
  {
    name: 'Macao',
    code: 'MO',
  },
  {
    name: 'Madagascar',
    code: 'MG',
  },
  {
    name: 'Malawi',
    code: 'MW',
  },
  {
    name: 'Malaysia',
    code: 'MY',
  },
  {
    name: 'Maldives',
    code: 'MV',
  },
  {
    name: 'Mali',
    code: 'ML',
  },
  {
    name: 'Malta',
    code: 'MT',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Martinique',
    code: 'MQ',
  },
  {
    name: 'Mauritania',
    code: 'MR',
  },
  {
    name: 'Mauritius',
    code: 'MU',
  },
  {
    name: 'Mayotte',
    code: 'YT',
  },
  {
    name: 'Mexico',
    code: 'MX',
  },
  {
    name: 'Micronesia (Federated States of)',
    code: 'FM',
  },
  {
    name: 'Moldova, Republic of',
    code: 'MD',
  },
  {
    name: 'Monaco',
    code: 'MC',
  },
  {
    name: 'Mongolia',
    code: 'MN',
  },
  {
    name: 'Montenegro (Balkan)',
    code: 'ME',
  },
  {
    name: 'Montserrat',
    code: 'MS',
  },
  {
    name: 'Morocco',
    code: 'MA',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
  },
  {
    name: 'Myanmar',
    code: 'MM',
  },
  {
    name: 'Namibia',
    code: 'NA',
  },
  {
    name: 'Nauru',
    code: 'NR',
  },
  {
    name: 'Nepal',
    code: 'NP',
  },
  {
    name: 'Netherlands',
    code: 'NL',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
  },
  {
    name: 'Niger',
    code: 'NE',
  },
  {
    name: 'Nigeria',
    code: 'NG',
  },
  {
    name: 'Niue',
    code: 'NU',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
  },
  {
    name: 'North Macedonia (Balkan)',
    code: 'MK',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Norway',
    code: 'NO',
  },
  {
    name: 'Oman',
    code: 'OM',
  },
  {
    name: 'Pakistan',
    code: 'PK',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
  {
    name: 'Palestine, State of',
    code: 'PS',
  },
  {
    name: 'Panama',
    code: 'PA',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
  },
  {
    name: 'Paraguay',
    code: 'PY',
  },
  {
    name: 'Peru',
    code: 'PE',
  },
  {
    name: 'Philippines',
    code: 'PH',
  },
  {
    name: 'Pitcairn',
    code: 'PN',
  },
  {
    name: 'Poland',
    code: 'PL',
  },
  {
    name: 'Portugal',
    code: 'PT',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Qatar',
    code: 'QA',
  },
  {
    name: 'R\u00e9union',
    code: 'RE',
  },
  {
    name: 'Romania',
    code: 'RO',
  },
  {
    name: 'Russian Federation',
    code: 'RU',
  },
  {
    name: 'Rwanda',
    code: 'RW',
  },
  {
    name: 'Saint Barth\u00e9lemy',
    code: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
  },
  {
    name: 'Saint Martin (French part)',
    code: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
  },
  {
    name: 'Samoa',
    code: 'WS',
  },
  {
    name: 'San Marino',
    code: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    code: 'ST',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
  },
  {
    name: 'Senegal',
    code: 'SN',
  },
  {
    name: 'Serbia (Balkan)',
    code: 'RS',
  },
  {
    name: 'Seychelles',
    code: 'SC',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
  },
  {
    name: 'Singapore',
    code: 'SG',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    code: 'SX',
  },
  {
    name: 'Slovakia',
    code: 'SK',
  },
  {
    name: 'Slovenia',
    code: 'SI',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
  },
  {
    name: 'Somalia',
    code: 'SO',
  },
  {
    name: 'South Africa',
    code: 'ZA',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
  },
  {
    name: 'South Sudan',
    code: 'SS',
  },
  {
    name: 'Spain',
    code: 'ES',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
  },
  {
    name: 'Sudan',
    code: 'SD',
  },
  {
    name: 'Suriname',
    code: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
  },
  {
    name: 'Sweden',
    code: 'SE',
  },
  {
    name: 'Switzerland',
    code: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    code: 'SY',
  },
  {
    name: 'Taiwan, Province of China',
    code: 'TW',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZ',
  },
  {
    name: 'Thailand',
    code: 'TH',
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
  },
  {
    name: 'Togo',
    code: 'TG',
  },
  {
    name: 'Tokelau',
    code: 'TK',
  },
  {
    name: 'Tonga',
    code: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
  },
  {
    name: 'Tunisia',
    code: 'TN',
  },
  {
    name: 'Turkey',
    code: 'TR',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
  },
  {
    name: 'Uganda',
    code: 'UG',
  },
  {
    name: 'Ukraine',
    code: 'UA',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GB',
  },
  {
    name: 'United States of America',
    code: 'US',
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
  },
  {
    name: 'Uruguay',
    code: 'UY',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    code: 'VE',
  },
  {
    name: 'Viet Nam',
    code: 'VN',
  },
  {
    name: 'Virgin Islands (British)',
    code: 'VG',
  },
  {
    name: 'Virgin Islands (U.S.)',
    code: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
  },
  {
    name: 'Western Sahara',
    code: 'EH',
  },
  {
    name: 'Yemen',
    code: 'YE',
  },
  {
    name: 'Zambia',
    code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
  },
]

export const sectorList = [
  { name: 'Beveiliging', code: 'Beveiliging' },
  { name: 'Financiele sector', code: 'Financiele sector' },
  { name: 'Gezondheidszorg', code: 'Gezondheidszorg' },
  { name: 'ICT', code: 'ICT' },
  { name: 'Olie en Gas', code: 'Olie en Gas' },
  { name: 'Investeringsmaatschappij', code: 'Investeringsmaatschappij' },
  { name: 'Landbouw', code: 'Landbouw' },
  { name: 'Visserij', code: 'Visserij' },
  { name: 'Mijnbouw', code: 'Mijnbouw' },
  { name: 'Vastgoed', code: 'Vastgoed' },
  { name: 'Horeca', code: 'Horeca' },
  { name: 'Onderwijs', code: 'Onderwijs' },
  { name: 'Winkelier', code: 'Winkelier' },
  { name: 'Zorg', code: 'Zorg' },
  { name: 'Technische dienst', code: 'Technische dienst' },
  { name: 'Transport', code: 'Transport' },
  { name: 'Huisvlijt', code: 'Huisvlijt' },
  { name: 'Overheid', code: 'Overheid' },
  { name: 'Cassino', code: 'Cassino' },
  { name: 'Veeteelt', code: 'Veeteelt' },
  { name: 'Autohandelaar', code: 'Autohandelaar' },
  { name: 'Sociale uitkering', code: 'Sociale uitkering' },
]

export const natureOfBusinessList = [
  { name: 'Administratieve Werkzaamheden', code: 'Administratieve Werkzaamheden' },
  { name: 'Advertentie Bureau', code: 'Advertentie Bureau' },
  { name: 'Adviesbureau', code: 'Adviesbureau' },
  { name: 'Advokatenkantoor', code: 'Advokatenkantoor' },
  { name: 'Afvaltransport', code: 'Afvaltransport' },
  { name: 'Agrarisch bedrijf', code: 'Agrarisch bedrijf' },
  { name: 'Ambassade', code: 'Ambassade' },
  { name: 'Apotheek', code: 'Apotheek' },
  { name: 'Appartementen', code: 'Appartementen' },
  { name: 'Architektenbureau', code: 'Architektenbureau' },
  { name: 'Artsenpraktijk', code: 'Artsenpraktijk' },
  { name: 'Associatiebedrijf', code: 'Associatiebedrijf' },
  { name: 'Assurantie Maatschappij', code: 'Assurantie Maatschappij' },
  { name: 'Audio Shop', code: 'Audio Shop' },
  { name: 'Auto Accesoires', code: 'Auto Accesoires' },
  { name: 'Auto Herstelwerkplaats', code: 'Auto Herstelwerkplaats' },
  { name: 'Auto verf en verfaccesoires', code: 'Auto verf en verfaccesoires' },
  { name: 'Autoairco bedrijf', code: 'Autoairco bedrijf' },
  { name: 'Autohandel', code: 'Autohandel' },
  { name: 'Automatiseringsbedrijf', code: 'Automatiseringsbedrijf' },
  { name: 'Autoverhuurbedrijf', code: 'Autoverhuurbedrijf' },
  { name: 'Bakkerij', code: 'Bakkerij' },
  { name: 'Banden Service', code: 'Banden Service' },
  { name: 'Bankinstelling', code: 'Bankinstelling' },
  { name: 'Bar & Cafe', code: 'Bar & Cafe' },
  { name: 'Barbershop', code: 'Barbershop' },
  { name: 'Bed & Breakfast', code: 'Bed & Breakfast' },
  { name: 'Begrafenis Onderneming', code: 'Begrafenis Onderneming' },
  { name: 'Beheer en Onderhoud', code: 'Beheer en Onderhoud' },
  { name: 'Beheer van haven', code: 'Beheer van haven' },
  { name: 'Beheer/Onderhoud van Primaire wegen', code: 'Beheer/Onderhoud van Primaire wegen' },
  { name: 'Bejaarden Tehuis', code: 'Bejaarden Tehuis' },
  { name: 'Beladingsbedrijf', code: 'Beladingsbedrijf' },
  { name: 'Bemiddelingsbedrijf', code: 'Bemiddelingsbedrijf' },
  { name: 'Bemonsteringsbedrijf', code: 'Bemonsteringsbedrijf' },
  { name: 'Beton Industrie', code: 'Beton Industrie' },
  { name: 'Beveiligingsbedrijf', code: 'Beveiligingsbedrijf' },
  { name: 'Bibliotheek', code: 'Bibliotheek' },
  { name: 'Blikzagerij', code: 'Blikzagerij' },
  { name: 'Bloemisterij', code: 'Bloemisterij' },
  { name: 'Boekhandel', code: 'Boekhandel' },
  { name: 'Boerderij', code: 'Boerderij' },
  { name: 'Bosexploitatie', code: 'Bosexploitatie' },
  { name: 'Bouw en Civieltechnische Werken', code: 'Bouw en Civieltechnische Werken' },
  { name: 'Bouw- en Constructiebedrijf', code: 'Bouw- en Constructiebedrijf' },
  { name: 'Bouw- en Lasconstructiebedrijf', code: 'Bouw- en Lasconstructiebedrijf' },
  { name: 'Bouwbedrijf', code: 'Bouwbedrijf' },
  { name: 'Bouwmaterialen', code: 'Bouwmaterialen' },
  { name: 'Brandstof leverancier', code: 'Brandstof leverancier' },
  { name: 'Brom- & Fiets Handel', code: 'Brom- & Fiets Handel' },
  { name: 'Bromfiets onderdelen verkoop', code: 'Bromfiets onderdelen verkoop' },
  { name: 'Cafetaria', code: 'Cafetaria' },
  { name: 'Callcenter', code: 'Callcenter' },
  { name: 'Cambio', code: 'Cambio' },
  { name: 'Carwash', code: 'Carwash' },
  { name: 'Casino', code: 'Casino' },
  { name: 'Catering', code: 'Catering' },
  { name: 'Celshop', code: 'Celshop' },
  { name: 'Chemicalienbedrijf', code: 'Chemicalienbedrijf' },
  { name: 'Club', code: 'Club' },
  { name: 'Coaching and Training Centre', code: 'Coaching and Training Centre' },
  { name: 'Communicatiebedrijf', code: 'Communicatiebedrijf' },
  { name: 'Computerbedrijf', code: 'Computerbedrijf' },
  { name: 'Confectiebedrijf', code: 'Confectiebedrijf' },
  { name: 'Constructiebedrijf', code: 'Constructiebedrijf' },
  { name: 'Consultancy', code: 'Consultancy' },
  { name: 'Contractors', code: 'Contractors' },
  { name: 'Coöperatie', code: 'Coöperatie' },
  { name: 'Copy Centre', code: 'Copy Centre' },
  { name: 'Creche', code: 'Creche' },
  { name: 'Cyber Cafe', code: 'Cyber Cafe' },
  { name: 'Dakplatenfabriek', code: 'Dakplatenfabriek' },
  { name: 'Design', code: 'Design' },
  { name: 'Deurwaarderskantoor', code: 'Deurwaarderskantoor' },
  { name: 'Dienstverlening', code: 'Dienstverlening' },
  { name: 'Dierenasiel', code: 'Dierenasiel' },
  { name: 'Dierenpoli', code: 'Dierenpoli' },
  { name: 'Discotheek', code: 'Discotheek' },
  { name: 'Distributie', code: 'Distributie' },
  { name: 'Douane Expeditie', code: 'Douane Expeditie' },
  { name: 'Drugstore', code: 'Drugstore' },
  { name: 'Drukkerij', code: 'Drukkerij' },
  { name: 'Duikersbedrijf', code: 'Duikersbedrijf' },
  { name: 'Ecotoerisme', code: 'Ecotoerisme' },
  { name: 'Educatie', code: 'Educatie' },
  { name: 'Electrotechnisch Bedrijf', code: 'Electrotechnisch Bedrijf' },
  { name: 'Embroidery', code: 'Embroidery' },
  { name: 'Energie Bedrijf', code: 'Energie Bedrijf' },
  { name: 'Event Planning', code: 'Event Planning' },
  { name: 'Exploitatie Bedrijf', code: 'Exploitatie Bedrijf' },
  { name: 'Exploratiebedrijf', code: 'Exploratiebedrijf' },
  { name: 'Fabriek & Magazijn', code: 'Fabriek & Magazijn' },
  { name: 'Feestzaal', code: 'Feestzaal' },
  { name: 'Financieel Bureau', code: 'Financieel Bureau' },
  { name: 'Foam Fabriek', code: 'Foam Fabriek' },
  { name: 'Fotostudio', code: 'Fotostudio' },
  { name: 'Glashandel', code: 'Glashandel' },
  { name: 'Goud- en Zilversmid', code: 'Goud- en Zilversmid' },
  { name: 'Goudopkoop', code: 'Goudopkoop' },
  { name: 'Graafwerkzaamheden', code: 'Graafwerkzaamheden' },
  { name: 'Grafisch Ontwerp Bureau', code: 'Grafisch Ontwerp Bureau' },
  { name: 'Grondverzet & Transportbedrijf', code: 'Grondverzet & Transportbedrijf' },
  { name: 'Groothandel', code: 'Groothandel' },
  { name: 'Hair & Beauty Salon', code: 'Hair & Beauty Salon' },
  { name: 'Handelsonderneming', code: 'Handelsonderneming' },
  { name: 'Holdingmaatschappij', code: 'Holdingmaatschappij' },
  { name: 'Horeca/Wafelfabriek', code: 'Horeca/Wafelfabriek' },
  { name: 'Hotel', code: 'Hotel' },
  { name: 'Hout Exportbedrijf', code: 'Hout Exportbedrijf' },
  { name: 'Houthandel', code: 'Houthandel' },
  { name: 'Houtmarkt', code: 'Houtmarkt' },
  { name: 'Houtverwerkingsbedrijf', code: 'Houtverwerkingsbedrijf' },
  { name: 'Huishouding', code: 'Huishouding' },
  { name: 'ICT Bedrijf', code: 'ICT Bedrijf' },
  { name: 'ICT Dienstverlening', code: 'ICT Dienstverlening' },
  { name: 'Ijsfabriek en Cafetaria', code: 'Ijsfabriek en Cafetaria' },
  { name: 'Import / Export Bedrijf', code: 'Import / Export Bedrijf' },
  { name: 'Incasso Bedrijf', code: 'Incasso Bedrijf' },
  { name: 'Industrie', code: 'Industrie' },
  { name: 'Ingenieursbureau', code: 'Ingenieursbureau' },
  { name: 'Inklaring- en Transportbedrijf', code: 'Inklaring- en Transportbedrijf' },
  { name: 'Insectenbestrijding', code: 'Insectenbestrijding' },
  { name: 'Inspectie', code: 'Inspectie' },
  { name: 'Installatiebedrijf', code: 'Installatiebedrijf' },
  { name: 'Interieur Bedrijf', code: 'Interieur Bedrijf' },
  { name: 'Internationale Organisatie', code: 'Internationale Organisatie' },
  { name: 'Investments', code: 'Investments' },
  { name: 'Juwelier', code: 'Juwelier' },
  { name: 'Kansspelen', code: 'Kansspelen' },
  { name: 'Kantine', code: 'Kantine' },
  { name: 'Kantoor', code: 'Kantoor' },
  { name: 'Kapsalon', code: 'Kapsalon' },
  { name: 'Kartonnenfabriek', code: 'Kartonnenfabriek' },
  { name: 'Keramische Werken', code: 'Keramische Werken' },
  { name: 'Kerkgemeente', code: 'Kerkgemeente' },
  { name: 'Keuringsstation', code: 'Keuringsstation' },
  { name: 'Keurmeester', code: 'Keurmeester' },
  { name: 'Kinderopvang', code: 'Kinderopvang' },
  { name: 'Klusjesman', code: 'Klusjesman' },
  { name: 'Koeltechnisch Bedrijf', code: 'Koeltechnisch Bedrijf' },
  { name: 'Konfectie', code: 'Konfectie' },
  { name: 'Laboratorium', code: 'Laboratorium' },
  { name: 'Landbouw,Veeteelt & Visserij', code: 'Landbouw,Veeteelt & Visserij' },
  { name: 'Landmeetkundig Bureau', code: 'Landmeetkundig Bureau' },
  { name: 'Las- en Constructiebedrijf', code: 'Las- en Constructiebedrijf' },
  { name: 'Logistiek', code: 'Logistiek' },
  { name: 'Logopediepraktijk', code: 'Logopediepraktijk' },
  { name: 'Loodgieterij', code: 'Loodgieterij' },
  { name: 'Loterij shop', code: 'Loterij shop' },
  { name: 'Luchtvaartmaatschappij', code: 'Luchtvaartmaatschappij' },
  { name: 'Luchtvracht', code: 'Luchtvracht' },
  { name: 'Machine en Instrumenten Fabriek', code: 'Machine en Instrumenten Fabriek' },
  { name: 'Magazijn', code: 'Magazijn' },
  { name: 'Management', code: 'Management' },
  { name: 'Marketing & Sales', code: 'Marketing & Sales' },
  { name: 'Markt', code: 'Markt' },
  { name: 'Massagesalon', code: 'Massagesalon' },
  { name: 'Mastklimmers', code: 'Mastklimmers' },
  { name: 'Materiaal Verhuurbedrijf', code: 'Materiaal Verhuurbedrijf' },
  { name: 'Mediabedrijf', code: 'Mediabedrijf' },
  { name: 'Medisch Technisch Dienst', code: 'Medisch Technisch Dienst' },
  { name: 'Metaalbewerking', code: 'Metaalbewerking' },
  { name: 'Meubel & Interieur accesoires', code: 'Meubel & Interieur accesoires' },
  { name: 'Mijnbouw', code: 'Mijnbouw' },
  { name: 'Ministerie', code: 'Ministerie' },
  { name: 'Modellen bureau', code: 'Modellen bureau' },
  { name: 'Motoren verkoop en Reparatiebedrijf', code: 'Motoren verkoop en Reparatiebedrijf' },
  { name: 'Museum', code: 'Museum' },
  { name: 'N.G.O. en Opvang voor HIV Positieven', code: 'N.G.O. en Opvang voor HIV Positieven' },
  { name: 'Nacht Club', code: 'Nacht Club' },
  { name: 'Natuurbehoud', code: 'Natuurbehoud' },
  { name: 'Nierdialyse Centrum', code: 'Nierdialyse Centrum' },
  { name: 'Notariaat', code: 'Notariaat' },
  { name: 'Nutsbedrijf', code: 'Nutsbedrijf' },
  { name: 'Olie Raffinaderij', code: 'Olie Raffinaderij' },
  { name: 'Onderhoudsbedrijf', code: 'Onderhoudsbedrijf' },
  { name: 'Onderwijsinstelling', code: 'Onderwijsinstelling' },
  { name: 'Onderzoeks Instituut', code: 'Onderzoeks Instituut' },
  { name: 'Onroerend Goed', code: 'Onroerend Goed' },
  { name: 'ontbossen', code: 'ontbossen' },
  { name: 'Opkoop en Export rondhout', code: 'Opkoop en Export rondhout' },
  { name: 'Opleidingsinstituut', code: 'Opleidingsinstituut' },
  { name: 'Oppashuis', code: 'Oppashuis' },
  { name: 'Opruimingsdienst', code: 'Opruimingsdienst' },
  { name: 'Opslag Magazijn', code: 'Opslag Magazijn' },
  { name: 'Opticien', code: 'Opticien' },
  { name: 'Opvang van Volwassen m/e beperking (drugs-alcohol', code: 'Opvang van Volwassen m/e beperking (drugs-alcohol' },
  { name: 'Opvangcentrum', code: 'Opvangcentrum' },
  { name: 'Organisatie', code: 'Organisatie' },
  { name: 'Pakkettendienst', code: 'Pakkettendienst' },
  { name: 'Papierhandel', code: 'Papierhandel' },
  { name: 'Parkeren', code: 'Parkeren' },
  { name: 'Parmaceutisch Groothandel', code: 'Parmaceutisch Groothandel' },
  { name: 'Pelmolen', code: 'Pelmolen' },
  { name: 'Pension', code: 'Pension' },
  { name: 'Plastic Fabriek', code: 'Plastic Fabriek' },
  { name: 'Pluimvee', code: 'Pluimvee' },
  { name: 'Posterij', code: 'Posterij' },
  { name: 'Produktiebedrijf', code: 'Produktiebedrijf' },
  { name: 'Project Ontwikkeling', code: 'Project Ontwikkeling' },
  { name: 'Radio & TV reparatie', code: 'Radio & TV reparatie' },
  { name: 'Radiodiagnotic/Echografie', code: 'Radiodiagnotic/Echografie' },
  { name: 'Radiostation', code: 'Radiostation' },
  { name: 'Reclame Bureau', code: 'Reclame Bureau' },
  { name: 'Recreatie Oord', code: 'Recreatie Oord' },
  { name: 'Recycling', code: 'Recycling' },
  { name: 'Reisbureau', code: 'Reisbureau' },
  { name: 'Religieuze Orga', code: 'Religieuze Orga' },
  { name: 'Reparatie Electrische artikelen', code: 'Reparatie Electrische artikelen' },
  { name: 'Reparatie Monteur & Lasbedrijf', code: 'Reparatie Monteur & Lasbedrijf' },
  { name: 'Reparatie van Schepen', code: 'Reparatie van Schepen' },
  { name: 'Rijschool', code: 'Rijschool' },
  { name: 'Rijstbedrijf', code: 'Rijstbedrijf' },
  { name: 'Scheepsagent', code: 'Scheepsagent' },
  { name: 'Scheepvaart Regulatiebedrijf', code: 'Scheepvaart Regulatiebedrijf' },
  { name: 'Scheepvaartmaatschappij', code: 'Scheepvaartmaatschappij' },
  { name: 'Schietschool', code: 'Schietschool' },
  { name: 'Schildersbedrijf', code: 'Schildersbedrijf' },
  { name: 'School', code: 'School' },
  { name: 'Schoonmaakbedrijf', code: 'Schoonmaakbedrijf' },
  { name: 'Schrootbedrijf', code: 'Schrootbedrijf' },
  { name: 'Slachterij', code: 'Slachterij' },
  { name: 'Slagerij', code: 'Slagerij' },
  { name: 'Sleepbedrijf', code: 'Sleepbedrijf' },
  { name: 'Slijterij', code: 'Slijterij' },
  { name: 'Sloopbedrijf', code: 'Sloopbedrijf' },
  { name: 'Snackbar', code: 'Snackbar' },
  { name: 'Sociale Instelling', code: 'Sociale Instelling' },
  { name: 'Sportcentrum', code: 'Sportcentrum' },
  { name: 'Sportschool', code: 'Sportschool' },
  { name: 'Sportzaak', code: 'Sportzaak' },
  { name: 'Spuit -en Uitdeuk', code: 'Spuit -en Uitdeuk' },
  { name: 'Steenslag', code: 'Steenslag' },
  { name: 'Stichting', code: 'Stichting' },
  { name: 'Straalbedrijf', code: 'Straalbedrijf' },
  { name: 'Stuwadoorsbedrijf', code: 'Stuwadoorsbedrijf' },
  { name: 'Supermarkt', code: 'Supermarkt' },
  { name: 'Tandartsen Praktijk', code: 'Tandartsen Praktijk' },
  { name: 'Tandtechnici Lab', code: 'Tandtechnici Lab' },
  { name: 'Taxatie Bedrijf', code: 'Taxatie Bedrijf' },
  { name: 'Taxibedrijf', code: 'Taxibedrijf' },
  { name: 'Technisch bedrijf', code: 'Technisch bedrijf' },
  { name: 'Tehuis', code: 'Tehuis' },
  { name: 'Telecommunicatie Bedrijf', code: 'Telecommunicatie Bedrijf' },
  { name: 'Technische dienst', code: 'Technische dienst' },
  { name: 'Textiel', code: 'Textiel' },
  { name: 'Thuiszorg', code: 'Thuiszorg' },
  { name: 'Toerisme', code: 'Toerisme' },
  { name: 'Toezichthouder', code: 'Toezichthouder' },
  { name: 'Toiletpapierbedrijf', code: 'Toiletpapierbedrijf' },
  { name: 'Tourbedrijf', code: 'Tourbedrijf' },
  { name: 'Transp/distributie bedrijf voor brandst en smeerm.', code: 'Transp/distributie bedrijf voor brandst en smeerm.' },
  { name: 'Transportbedrijf', code: 'Transportbedrijf' },
  { name: 'Triplexfabriek', code: 'Triplexfabriek' },
  { name: 'Tuinbouw', code: 'Tuinbouw' },
  { name: 'Uitgeverij', code: 'Uitgeverij' },
  { name: 'Uitvaartbedrijf', code: 'Uitvaartbedrijf' },
  { name: 'Uitzendbureau', code: 'Uitzendbureau' },
  { name: 'Vastgoed', code: 'Vastgoed' },
  { name: 'Veldwerk', code: 'Veldwerk' },
  { name: 'Verf groothandel/Retail', code: 'Verf groothandel/Retail' },
  { name: 'Verhuurbedrijf', code: 'Verhuurbedrijf' },
  { name: 'Verkavelings- en Bouwbedrijf', code: 'Verkavelings- en Bouwbedrijf' },
  { name: 'Verlichtingsarmaturenfabriek', code: 'Verlichtingsarmaturenfabriek' },
  { name: 'Vervoersbedrijf', code: 'Vervoersbedrijf' },
  { name: 'Verwerkingsbedrijf', code: 'Verwerkingsbedrijf' },
  { name: 'Visserij', code: 'Visserij' },
  { name: 'Visverwerkingsbedrijf', code: 'Visverwerkingsbedrijf' },
  { name: 'Voedingstechnologiebedrijf', code: 'Voedingstechnologiebedrijf' },
  { name: 'Voertuig Techniek', code: 'Voertuig Techniek' },
  { name: 'Vuilophaal-en Verwerkingsdienst', code: 'Vuilophaal-en Verwerkingsdienst' },
  { name: 'Waardetransport', code: 'Waardetransport' },
  { name: 'Wasserij', code: 'Wasserij' },
  { name: 'Wateroplossing', code: 'Wateroplossing' },
  { name: 'Watervoorziening', code: 'Watervoorziening' },
  { name: 'Winkel', code: 'Winkel' },
  { name: 'Zoo', code: 'Zoo' },
  { name: 'Zuivelfabriek', code: 'Zuivelfabriek' },
  { name: 'Zuurstoffabriek', code: 'Zuurstoffabriek' },
  { name: 'Zwaar materiaal', code: 'Zwaar materiaal' },
  { name: 'Zwembad', code: 'Zwembad' },
  { name: 'Zwemschool', code: 'Zwemschool' },
]

export const surinameCityList = [
  { name: 'Albina', code: 'Albina' },
  { name: 'Alkmaar', code: 'Alkmaar' },
  { name: 'Bakie', code: 'Bakie' },
  { name: 'Beekhuizen', code: 'Beekhuizen' },
  { name: 'Bigi Poika', code: 'Bigi Poika' },
  { name: 'Blauwgrond', code: 'Blauwgrond' },
  { name: 'Boven-Coppename', code: 'Boven-Coppename' },
  { name: 'Boven-Saramacca', code: 'Boven-Saramacca' },
  { name: 'Boven-Suriname', code: 'Boven-Suriname' },
  { name: 'Brownsweg', code: 'Brownsweg' },
  { name: 'Calcutta', code: 'Calcutta' },
  { name: 'Carolina', code: 'Carolina' },
  { name: 'Centrum', code: 'Centrum' },
  { name: 'Coeroeni', code: 'Coeroeni' },
  { name: 'De Nieuwe Grond', code: 'De Nieuwe Grond' },
  { name: 'Domburg', code: 'Domburg' },
  { name: 'Flora', code: 'Flora' },
  { name: 'Galibi', code: 'Galibi' },
  { name: 'Groningen', code: 'Groningen' },
  { name: 'Groot Henar', code: 'Groot Henar' },
  { name: 'Houttuin', code: 'Houttuin' },
  { name: 'Jarikaba', code: 'Jarikaba' },
  { name: 'Johanna Maria', code: 'Johanna Maria' },
  { name: 'Kabalebo', code: 'Kabalebo' },
  { name: 'Kampong Baroe', code: 'Kampong Baroe' },
  { name: 'Klaaskreek', code: 'Klaaskreek' },
  { name: 'Koewarasan', code: 'Koewarasan' },
  { name: 'Kwakoegron', code: 'Kwakoegron' },
  { name: 'Kwatta', code: 'Kwatta' },
  { name: 'Latour', code: 'Latour' },
  { name: 'Lelydorp', code: 'Lelydorp' },
  { name: 'Livorno', code: 'Livorno' },
  { name: 'Margaretha', code: 'Margaretha' },
  { name: 'Marshallkreek', code: 'Marshallkreek' },
  { name: 'Meerzorg', code: 'Meerzorg' },
  { name: 'Moengo', code: 'Moengo' },
  { name: 'Moengo Tapoe', code: 'Moengo Tapoe' },
  { name: 'Munder', code: 'Munder' },
  { name: 'Nieuw-Amsterdam', code: 'Nieuw-Amsterdam' },
  { name: 'Nieuw-Nickerie', code: 'Nieuw-Nickerie' },
  { name: 'Noord', code: 'Noord' },
  { name: 'Oost', code: 'Oost' },
  { name: 'Oostelijke Polders', code: 'Oostelijke Polders' },
  { name: 'Patamacca', code: 'Patamacca' },
  { name: 'Pontbuiten', code: 'Pontbuiten' },
  { name: 'Rainville', code: 'Rainville' },
  { name: 'Sarakreek', code: 'Sarakreek' },
  { name: 'Saramacca Polder', code: 'Saramacca Polder' },
  { name: 'Tamanredjo', code: 'Tamanredjo' },
  { name: 'Tammenga', code: 'Tammenga' },
  { name: 'Tapanahoni', code: 'Tapanahoni' },
  { name: 'Tijgerkreek', code: 'Tijgerkreek' },
  { name: 'Totness', code: 'Totness' },
  { name: 'Wageningen', code: 'Wageningen' },
  { name: 'Wanhatti', code: 'Wanhatti' },
  { name: 'Wayamboweg', code: 'Wayamboweg' },
  { name: 'Weg Naar Zee', code: 'Weg Naar Zee' },
  { name: 'Welgelegen', code: 'Welgelegen' },
  { name: 'Westelijke Polders', code: 'Westelijke Polders' },
  { name: 'Zuid', code: 'Zuid' },
]

export const currencyList = [
  { name: 'SRD', code: 'SRD' },
  { name: 'USD', code: 'USD' },
  { name: 'EURO', code: 'EURO' },
]

export const bankList = [
  { name: 'De Surinaamse Bank', code: '1' },
  { name: 'Surinaamse Volkscredietbank', code: '2' },
  { name: 'Hakrinbank NV', code: '3' },
  { name: 'Republic Bank', code: '4' },
  { name: 'Surichange Bank NV', code: '5' },
  { name: 'GODO', code: '6' },
  { name: 'Surinaamse Post Spaar Bank', code: '7' },
  { name: 'Trustbank Amanah', code: '8' },
  { name: 'Finabank', code: '9' },
]

export const nationalityList = [
  { name: 'Afghaans', code: 'Afghaans' },
  { name: 'Albanees', code: 'Albanees' },
  { name: 'Algerijn', code: 'Algerijn' },
  { name: 'Andorrese', code: 'Andorrese' },
  { name: 'Angolese', code: 'Angolese' },
  { name: 'Antiguan of Barbudan', code: 'Antiguan of Barbudan' },
  { name: 'Argentinië', code: 'Argentinië' },
  { name: 'Armeens', code: 'Armeens' },
  { name: 'Australisch', code: 'Australisch' },
  { name: 'Oostenrijks', code: 'Oostenrijks' },
  { name: 'Azeri', code: 'Azeri' },
  { name: 'Bahamaanse', code: 'Bahamaanse' },
  { name: 'Bahreinse', code: 'Bahreinse' },
  { name: 'Bengalees', code: 'Bengalees' },
  { name: 'Barbadaans', code: 'Barbadaans' },
  { name: 'Belarusian', code: 'Belarusian' },
  { name: 'Belgisch', code: 'Belgisch' },
  { name: 'Belizean', code: 'Belizean' },
  { name: 'Beninees', code: 'Beninees' },
  { name: 'Bhutanese', code: 'Bhutanese' },
  { name: 'Boliviaanse', code: 'Boliviaanse' },
  { name: 'Bosniër of Herzegoviër', code: 'Bosniër of Herzegoviër' },
  { name: 'Motswana, Botswanan', code: 'Motswana, Botswanan' },
  { name: 'Braziliaans', code: 'Braziliaans' },
  { name: 'Bruneian', code: 'Bruneian' },
  { name: 'Bulgarian', code: 'Bulgarian' },
  { name: 'Burkinabé', code: 'Burkinabé' },
  { name: 'Birmaans', code: 'Birmaans' },
  { name: 'Burundese', code: 'Burundese' },
  { name: 'Cabo Verdean', code: 'Cabo Verdean' },
  { name: 'Cambodjaans', code: 'Cambodjaans' },
  { name: 'Kameroense', code: 'Kameroense' },
  { name: 'Canadees', code: 'Canadees' },
  { name: 'Centraal-Afrikaan', code: 'Centraal-Afrikaan' },
  { name: 'Tsjadische', code: 'Tsjadische' },
  { name: 'Chileens', code: 'Chileens' },
  { name: 'Chinese', code: 'Chinese' },
  { name: 'Colombiaans', code: 'Colombiaans' },
  { name: 'Comorian', code: 'Comorian' },
  { name: 'Congolees', code: 'Congolees' },
  { name: 'Costa Ricaans', code: 'Costa Ricaans' },
  { name: 'Kroatisch', code: 'Kroatisch' },
  { name: 'Cubaans', code: 'Cubaans' },
  { name: 'Cypriotisch', code: 'Cypriotisch' },
  { name: 'Tsjechisch', code: 'Tsjechisch' },
  { name: 'Deens', code: 'Deens' },
  { name: 'Djiboutiaanse', code: 'Djiboutiaanse' },
  { name: 'dominicaans', code: 'dominicaans' },
  { name: 'Ecuadoriaans', code: 'Ecuadoriaans' },
  { name: 'Egyptische', code: 'Egyptische' },
  { name: 'Salvadoraanse', code: 'Salvadoraanse' },
  { name: 'Equatoguinean', code: 'Equatoguinean' },
  { name: 'Eritrese', code: 'Eritrese' },
  { name: 'Estlands', code: 'Estlands' },
  { name: 'Ethiopische', code: 'Ethiopische' },
  { name: 'fijian', code: 'fijian' },
  { name: 'Finnish', code: 'Finnish' },
  { name: 'French', code: 'French' },
  { name: 'Gabonese', code: 'Gabonese' },
  { name: 'Gambiaanse', code: 'Gambiaanse' },
  { name: 'Georgisch', code: 'Georgisch' },
  { name: 'German', code: 'German' },
  { name: 'Ghanees', code: 'Ghanees' },
  { name: 'Gibraltar', code: 'Gibraltar' },
  { name: 'Grieks', code: 'Grieks' },
  { name: 'Grenadian', code: 'Grenadian' },
  { name: 'Guatemalteeks', code: 'Guatemalteeks' },
  { name: 'Guinese', code: 'Guinese' },
  { name: 'Bissau-Guinese', code: 'Bissau-Guinese' },
  { name: 'Guyanese', code: 'Guyanese' },
  { name: 'Haïtiaanse', code: 'Haïtiaanse' },
  { name: 'Hondurees', code: 'Hondurees' },
  { name: 'Hongaars', code: 'Hongaars' },
  { name: 'IJslands', code: 'IJslands' },
  { name: 'Indiaas', code: 'Indiaas' },
  { name: 'Indonesian', code: 'Indonesian' },
  { name: 'Iraans', code: 'Iraans' },
  { name: 'Iraaks', code: 'Iraaks' },
  { name: 'Iers', code: 'Iers' },
  { name: 'Israëlisch', code: 'Israëlisch' },
  { name: 'Italian', code: 'Italian' },
  { name: 'Ivoriaanse', code: 'Ivoriaanse' },
  { name: 'Jamaican', code: 'Jamaican' },
  { name: 'Japanese', code: 'Japanese' },
  { name: 'Jordaanse', code: 'Jordaanse' },
  { name: 'Kazachstani', code: 'Kazachstani' },
  { name: 'Keniaan', code: 'Keniaan' },
  { name: 'I-Kiribati', code: 'I-Kiribati' },
  { name: 'Noord-Koreaanse', code: 'Noord-Koreaanse' },
  { name: 'Zuid-Koreaanse', code: 'Zuid-Koreaanse' },
  { name: 'Koeweitse', code: 'Koeweitse' },
  { name: 'Kirgizië', code: 'Kirgizië' },
  { name: 'Laotiaans', code: 'Laotiaans' },
  { name: 'Lettish', code: 'Letlands' },
  { name: 'Libanees', code: 'Libanees' },
  { name: 'Basotho', code: 'Basotho' },
  { name: 'Liberiaans', code: 'Liberiaans' },
  { name: 'Libisch', code: 'Libisch' },
  { name: 'Liechtenstein is', code: 'Liechtenstein is' },
  { name: 'Litouws', code: 'Litouws' },
  { name: 'Luxemburgs', code: 'Luxemburgs' },
  { name: 'Macedonisch', code: 'Macedonisch' },
  { name: 'Malagasi', code: 'Malagasi' },
  { name: 'Malawiaanse', code: 'Malawiaanse' },
  { name: 'Maleisisch', code: 'Maleisisch' },
  { name: 'Maldivische', code: 'Maldivische' },
  { name: 'Malinees', code: 'Malinees' },
  { name: 'Maltees', code: 'Maltees' },
  { name: 'Marshallese', code: 'Marshallese' },
  { name: 'Martinican', code: 'Martinican' },
  { name: 'Mauritaanse', code: 'Mauritaanse' },
  { name: 'Mauritiaanse', code: 'Mauritiaanse' },
  { name: 'Mexicaans', code: 'Mexicaans' },
  { name: 'Micronesische', code: 'Micronesische' },
  { name: 'Moldovan', code: 'Moldovan' },
  { name: 'Monégasque', code: 'Monégasque' },
  { name: 'Mongools', code: 'Mongools' },
  { name: 'Montenegrijnse', code: 'Montenegrijnse' },
  { name: 'Marokkaans', code: 'Marokkaans' },
  { name: 'Mozambikaanse', code: 'Mozambikaanse' },
  { name: 'Namibisch', code: 'Namibisch' },
  { name: 'Nauruan', code: 'Nauruan' },
  { name: 'Nepalees', code: 'Nepalees' },
  { name: 'Nederlands', code: 'Nederlands' },
  { name: 'Zelanian', code: 'Zelanian' },
  { name: 'Nicaraguaans', code: 'Nicaraguaans' },
  { name: 'Nigerese', code: 'Nigerese' },
  { name: 'Nigeriaanse', code: 'Nigeriaanse' },
  { name: 'Northern Marianan', code: 'Northern Marianan' },
  { name: 'Norwegian', code: 'Norwegian' },
  { name: 'Omani', code: 'Omani' },
  { name: 'Pakistaans', code: 'Pakistaans' },
  { name: 'Palauan', code: 'Palauan' },
  { name: 'Palestijns', code: 'Palestijns' },
  { name: 'Panamees', code: 'Panamees' },
  { name: 'Papuan', code: 'Papuan' },
  { name: 'Paraguayaans', code: 'Paraguayaans' },
  { name: 'Peruviaans', code: 'Peruviaans' },
  { name: 'Filippijns', code: 'Filippijns' },
  { name: 'Polijsten', code: 'polijsten' },
  { name: 'Portuguese', code: 'Portuguese' },
  { name: 'Puerto Ricaan', code: 'Puerto Ricaan' },
  { name: 'Qatari', code: 'Qatari' },
  { name: 'Roemeense', code: 'Roemeense' },
  { name: 'Russian', code: 'Russian' },
  { name: 'Rwandese', code: 'Rwandese' },
  { name: 'Kittitian', code: 'Kittitian' },
  { name: 'Saint Lucian', code: 'Saint Lucian' },
  { name: 'Vincentiaan', code: 'Vincentiaan' },
  { name: 'Samoan', code: 'Samoan' },
  { name: 'San Marino', code: 'San Marino' },
  { name: 'São Toméan', code: 'São Toméan' },
  { name: 'Saoedi-Arabië', code: 'Saoedi-Arabië' },
  { name: 'Senegalees', code: 'Senegalees' },
  { name: 'Servisch', code: 'Servisch' },
  { name: 'Seychellen', code: 'Seychellen' },
  { name: 'Sierra Leonean', code: 'Sierra Leonean' },
  { name: 'Singaporean', code: 'Singaporean' },
  { name: 'Slovak', code: 'Slovak' },
  { name: 'Sloveens', code: 'Sloveens' },
  { name: 'Solomon Island', code: 'Solomon Island' },
  { name: 'Somalisch', code: 'Somalisch' },
  { name: 'Zuid-Afrikaans', code: 'Zuid-Afrikaans' },
  { name: 'Zuid-Soedanees', code: 'Zuid-Soedanees' },
  { name: 'Spanish', code: 'Spanish' },
  { name: 'Sri Lankaans', code: 'Sri Lankaans' },
  { name: 'Soedanees', code: 'Soedanees' },
  { name: 'Surinaams', code: 'Surinaams' },
  { name: 'Swazi', code: 'Swazi' },
  { name: 'Swedish', code: 'Swedish' },
  { name: 'Zwitsers', code: 'Zwitsers' },
  { name: 'Syrisch', code: 'Syrisch' },
  { name: 'Tadzjiekse', code: 'Tadzjiekse' },
  { name: 'Tanzaniaans', code: 'Tanzaniaans' },
  { name: 'Thai', code: 'Thai' },
  { name: 'Timorese', code: 'Timorese' },
  { name: 'Togolese', code: 'Togolese' },
  { name: 'Tokelauan', code: 'Tokelauan' },
  { name: 'Tongaanse', code: 'Tongaanse' },
  { name: 'Trinidadian of Tobagonian', code: 'Trinidadian of Tobagonian' },
  { name: 'Tunesische', code: 'Tunesische' },
  { name: 'Turks', code: 'Turks' },
  { name: 'Turkmeense', code: 'Turkmeense' },
  { name: 'Tuvaluan', code: 'Tuvaluan' },
  { name: 'Oegandees', code: 'Oegandees' },
  { name: 'Oekraïens', code: 'Oekraïens' },
  { name: 'Emirati', code: 'Emirati' },
  { name: 'VK, Brits', code: 'VK, Brits' },
  { name: 'Verenigde Staten, VS, Amerikaans', code: 'Amerikaans' },
  { name: 'Uruguayaans', code: 'Uruguayaans' },
  { name: 'Oezbeeks', code: 'Oezbeeks' },
  { name: 'Vanuatuan', code: 'Vanuatuan' },
  { name: 'Vaticaan', code: 'Vaticaan' },
  { name: 'Venezolaans', code: 'Venezolaans' },
  { name: 'Vietnamees', code: 'Vietnamees' },
  { name: 'Jemenitisch', code: 'Jemenitisch' },
  { name: 'Zambiaanse', code: 'Zambiaanse' },
  { name: 'Zimbabwaanse', code: 'Zimbabwaanse' },
]
